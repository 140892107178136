import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Outlet} from 'react-router-dom';

const theme = createTheme();

export default function Layout() {
 
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
            <Outlet/>
      </Container>
    </ThemeProvider>
  );
}