import Layout from './components/layout/layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage'
import DataEntryPage from './pages/DataEntry/DataEntryPage';



function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage/>} /> 
          <Route element={<Layout />} > 
             <Route path="/dataentry/:formid" element={<DataEntryPage />} /> 
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
