import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dataentryReducer from '../reducers/dataentryReducer';


const rootReducer = combineReducers({
  dataentry: dataentryReducer,
});

const store = configureStore({
  reducer: rootReducer
});

export default store;