import axios from 'axios';
const apiConfig= require('../../config/apiConfig');
export const loadForm=(formid)=>{
  return function(dispatch,getState) {
    dispatch({ type: 'LOAD_FORM_REQUEST' });
    axios.get(apiConfig.apiURL+"/formstatic/"+formid, {})
      .then(response => {
        console.log(response)
        dispatch({ type: 'LOAD_FORM_SUCCESS',  payload: response.data });
      })
      .catch(error => {
         dispatch({ type: 'LOAD_FORM_FAILURE', payload: error.message });
      });
  };
}

export const submitFormData = (formID,formData) =>{
  return function(dispatch,getState) {
    dispatch({ type: 'SUBMIT_DATA_REQUEST' });
    const submitData={
      formData:formData
    }
    console.log(submitData)
    axios.post(apiConfig.apiURL+"/datas/form/"+formID,submitData)
      .then(response => {
        console.log("Add Data to Form",response)
        dispatch({ type: 'SUBMIT_DATA_SUCCESS',  payload: response.data });
      })
      .catch(error => {
         dispatch({ type: 'SUBMIT_DATA_FAILURE', payload: error.message });
      });
  };
};
