const initialState = {
    dataSumitted :{},
    formSchema:{},
    error:null,
};
  
  export default function fieldReducer(state = initialState, action) {
    switch (action.type) {
      case 'LOAD_FORM_REQUEST':
        return { ...state, isLoading: true, error: null };
      case 'LOAD_FORM_SUCCESS':
        return { ...state, 
                isLoading:false, 
                formSchema:action.payload,
                error:null};
      case 'LOAD_FORM_FAILURE':
        return { ...state, isLoading:false,error: action.payload };
      case 'SUBMIT_DATA_REQUEST':
        return { ...state, isLoading: true, error: null };
      case 'SUBMIT_DATA_SUCCESS':
        return { ...state, 
                isLoading:false, 
                dataSumitted:action.payload,
                error:null};
      case 'SUBMIT_DATA_FAILURE':
        return { ...state, isLoading:false,error: action.payload };
      default:
        return state;
    }
  }
  