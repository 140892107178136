import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect,useSelector } from "react-redux";
import { loadForm,submitFormData } from "../../redux/actions/dataentryActions";
import { RJSFSchema } from '@rjsf/utils';
import Box from '@mui/material/Box';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';



function DataEntryPage(props) {
  const formSchema = useSelector(state => state.dataentry.formSchema);
  const schema: RJSFSchema = formSchema.staticform ? formSchema.staticform :{}
  const { formid } = useParams();

  useEffect(() => {
     props.loadForm(formid)
     console.log(formSchema)
  }, [props,formid]);
 
  const handleSubmit = ({ formData }, e) => {
    const newFormData = Object.keys(formData).map((item)=>{
       return {fieldID:item,fieldData:formData[item]}
    })
   // console.log(newFormData)
    props.submitFormData(formid,newFormData)
  };

  return (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Form 
          schema={schema} 
          validator={validator}
          onSubmit={handleSubmit}
           />
        </Box>
  );
}

const mapStateToProps = state => ({
  error:null
});

const mapDispatchToProps = {
  loadForm,
  submitFormData
};

export default connect(mapStateToProps,mapDispatchToProps)(DataEntryPage);